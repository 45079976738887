import { Alert, AlertIcon, Menu, MenuButton, MenuList, MenuItem, HStack } from "@chakra-ui/react";
import { Header } from "../../../../components";
import { useAllStoresReport } from "../../../../hooks";
import { LoadingPage } from "../../../LoadingPage";
import { useState, useEffect, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { VirtualizedDataTable, Search } from "../../../../components";
import { formatDateTimeDisplay } from "../../../../lib";
import { IconCheck, IconX } from "@tabler/icons-react";
import { ChevronDownIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { createPortal } from "react-dom";
import { AllStoresReportData } from "../../../../types";

const searchFilterFunction = (data: AllStoresReportData, searchTerm: string) => {
    return data.storeHostname.toLowerCase().includes(searchTerm);
};

export const AllStoresReport = () => {
    const { data: allStoresData, isLoading, isError } = useAllStoresReport();
    const [tableData, setTableData] = useState<AllStoresReportData[]>([]);
    const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<AllStoresReportData[]>([]);
    console.log(rows);
    useMemo(() => {
        if (allStoresData === undefined) return;

        const indexes = Object.keys(allStoresData.storeHostname);
        const dataArray = indexes.map((index) => {
            let row: any = {};
            for (const column in allStoresData) {
                row[column] = allStoresData[column][index];
            }
            return row;
        });
        setTableData(dataArray);
    }, [allStoresData]);

    useEffect(() => {
        setRows(tableData);
    }, [tableData]);

    const columns = useMemo(buildColumns, []);

    return (
        <>
            <HStack justifyContent={"space-between"} alignItems={"center"} mb="spacer-8">
                <Header>All Stores Report</Header>
                <Search
                    data={tableData}
                    setFilteredData={setRows}
                    setSearchIsLoading={setSearchIsLoading}
                    filterFunction={searchFilterFunction}
                />
            </HStack>
            {isError ? (
                <Alert status="error">
                    <AlertIcon />
                    There was an error fetching the data.
                </Alert>
            ) : (isLoading || searchIsLoading) && !isError ? (
                <LoadingPage />
            ) : tableData?.length === 0 || rows?.length === 0 ? (
                <Alert status="info">
                    <AlertIcon />
                    There is no data recorded.
                </Alert>
            ) : (
                <VirtualizedDataTable data={rows} columns={columns} tableShift="auto" />
            )}
        </>
    );
};

const buildColumns = () => {
    const columnHelper = createColumnHelper<AllStoresReportData>();
    const columns = [
        columnHelper.accessor("storeHostname", {
            cell: (props) => (
                <>
                    <Menu>
                        <MenuButton>
                            <HStack>
                                <ChevronDownIcon />
                                <span className="trackCustomerUrl">{props.getValue()}</span>
                            </HStack>
                        </MenuButton>
                        {createPortal(
                            <MenuList style={{ zIndex: 1000, position: "absolute", overflow: "visible" }}>
                                <MenuItem
                                    icon={<ExternalLinkIcon />}
                                    as="a"
                                    target="_blank"
                                    href={`https://admin.shopify.com/store/${props.row.original.ecomUrl.split(".")[0]}`}
                                >
                                    Shopify Admin
                                </MenuItem>
                                <MenuItem
                                    icon={<ExternalLinkIcon />}
                                    as="a"
                                    target="_blank"
                                    href={`https://${props.getValue()}`}
                                >
                                    {props.getValue()}
                                </MenuItem>
                            </MenuList>,
                            document.body,
                        )}
                    </Menu>
                </>
            ),
            header: "Customer URL",
        }),
        columnHelper.accessor("edgeEnabled", {
            cell: (props) => {
                if (props.getValue() === true) {
                    return <IconCheck />;
                } else return <IconX />;
            },
            header: "Edge Enabled",
        }),
        columnHelper.accessor("edgeEnabledAt", {
            cell: (props) => {
                if (props.getValue()) {
                    return formatDateTimeDisplay(props.getValue());
                } else return "No data";
            },
            header: "Edge Enabled At",
        }),
        columnHelper.accessor("crawlerOptimizationEnabled", {
            cell: (props) => {
                if (props.getValue() === true) {
                    return <IconCheck />;
                } else return <IconX />;
            },
            header: "Crawler Enabled",
        }),
        columnHelper.accessor("crawlerOptimizationEnabledAt", {
            cell: (props) => {
                if (props.getValue()) {
                    return formatDateTimeDisplay(props.getValue());
                } else return "No data";
            },
            header: "Crawler Enabled At",
        }),
        columnHelper.accessor("experimentationEnabled", {
            cell: (props) => {
                if (props.getValue() === true) {
                    return <IconCheck />;
                } else return <IconX />;
            },
            header: "Speed Experiment Enabled",
        }),
        columnHelper.accessor("experimentationStartDate", {
            cell: (props) => {
                if (props.getValue()) {
                    return formatDateTimeDisplay(props.getValue());
                } else return "No data";
            },
            header: "Experiment Start Date",
        }),
        columnHelper.accessor("weightEdge", {
            cell: (props) => {
                if (props.getValue()) {
                    const weight = (props.getValue?.() ?? 0) * 100;
                    return weight + "%";
                } else return "No data";
            },
            header: "Edge Delivery Weight",
        }),
        columnHelper.accessor("weightOrigin", {
            cell: (props) => {
                if (props.getValue()) {
                    const weight = (props.getValue?.() ?? 0) * 100;
                    return weight + "%";
                } else return "No data";
            },
            header: "Control Weight",
        }),
        // TODO: Add back in when sessions are finalized in backend
        // columnHelper.accessor("sessionsWeek", {
        //     cell: (props) => {
        //         if (props.getValue()) {
        //             return new Intl.NumberFormat("en-US").format(props.getValue());
        //         } else return "No data";
        //     },
        //     header: "Week Sessions",
        // }),
        // columnHelper.accessor("sessionsMonth", {
        //     cell: (props) => {
        //         if (props.getValue()) {
        //             return new Intl.NumberFormat("en-US").format(props.getValue());
        //         } else return "No data";
        //     },
        //     header: "Month Sessions",
        // }),
        // columnHelper.accessor("sessionsQuarter", {
        //     cell: (props) => {
        //         if (props.getValue()) {
        //             return new Intl.NumberFormat("en-US").format(props.getValue());
        //         } else return "No data";
        //     },
        //     header: "Quarter Sessions",
        // }),
        // columnHelper.accessor("sessionsYear", {
        //     cell: (props) => {
        //         if (props.getValue()) {
        //             return new Intl.NumberFormat("en-US").format(props.getValue());
        //         } else return "No data";
        //     },
        //     header: "Annual Sessions",
        // }),
        columnHelper.accessor("orderSyncEnabled", {
            cell: (props) => {
                if (props.getValue() === true) {
                    return <IconCheck />;
                } else return <IconX />;
            },
            header: "Order Sync",
        }),
        columnHelper.accessor("orderSyncCursor", {
            cell: (props) => {
                if (props.getValue()) {
                    return <IconCheck />;
                } else return <IconX />;
            },
            header: "Order Sync Cursor",
        }),
        columnHelper.accessor("lastOrderSeen", {
            cell: (props) => {
                if (props.getValue()) {
                    return formatDateTimeDisplay(props.getValue());
                } else return "No data";
            },
            header: "Last Order Sync",
        }),
    ];

    return columns;
};
